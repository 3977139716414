import type { AppProps } from 'next/app';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { appWithTranslation } from 'next-i18next';
import MainLayout from '@/components/MainLayout';
import PageTitle from '@/components/PageTitle';
import Theme from '@/theme';
import '../../styles/globals.css';
import GlobalProvider from '@/components/GlobalProvider';
import type { ProgrammeId } from '@/common/types';
import DatadogRum from '@/components/DatadogRum';

const LinkingApp = ({ Component, pageProps }: AppProps) => {
  const {
    programmeId,
    singleAviosBalance,
  }: { programmeId: ProgrammeId; singleAviosBalance: number } = pageProps;

  return (
    <GlobalProvider
      programmeId={programmeId}
      singleAviosBalance={singleAviosBalance}
    >
      <DatadogRum />
      <UserProvider loginUrl="/api/auth/login" profileUrl="/api/auth/me">
        <PageTitle />
        <Theme programmeId={programmeId}>
          <MainLayout {...pageProps}>
            <Component {...pageProps} />
          </MainLayout>
        </Theme>
      </UserProvider>
    </GlobalProvider>
  );
};

export default appWithTranslation(LinkingApp);
