import type { ProgrammeId } from '@/common/types';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

export type GlobalContextProps = {
  programmeId?: ProgrammeId;
  singleAviosBalance?: number;
  setSingleAviosBalance?: (balance: number) => void;
};

const GlobalContext = createContext<GlobalContextProps>({});

type GlobalProviderProps = {
  programmeId: ProgrammeId;
  singleAviosBalance: number;
};

export function Index({
  programmeId,
  singleAviosBalance: inputSingleAviosBalance,
  children,
}: Readonly<PropsWithChildren<GlobalProviderProps>>) {
  const [singleAviosBalance, setSingleAviosBalance] = useState<number>(inputSingleAviosBalance);
  const value = useMemo<GlobalContextProps>(
    () => ({
      programmeId,
      singleAviosBalance,
      setSingleAviosBalance,
    }),
    [programmeId, singleAviosBalance],
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = (): GlobalContextProps => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('Failed to access the global context.');
  }

  return context;
};

export default Index;
